$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';
$assetPath: $cdnAssetsUrl + '/themes/' + $vendor;
// $shadowHeight: 19px;

.section {
  margin-bottom: 40px;
  max-width: var(--vw-xxl);
  margin: 0 auto 32px auto;
}

// // TODO: Remove gradients once Branding page is finalized
// // - gradients won't be necessary once all sections are complete
// .bgLight {
//   background: map-get($colors, white);
//   // background: -moz-linear-gradient(top, map-get($colors, gray800) 0%, map-get($colors, gray900) 20%); /* FF3.6-15 */
//   // background: -webkit-linear-gradient(top, map-get($colors, gray800) 0%, map-get($colors, gray900) 20%); /* Chrome10-25,Safari5.1-6 */
//   // background: linear-gradient(to bottom, map-get($colors, gray800) 0%, map-get($colors, gray900) 20%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='map-get($colors, gray800)', endColorstr='map-get($colors, gray900)', GradientType=0 ); /* IE6-9 */
//   position: relative;
//   &:before {
//     content: '';
//     display: block;
//     position: absolute;
//     bottom: 0 - $shadowHeight;
//     width: 100%;
//     height: $shadowHeight;
//     background: url($assetPath + '/whcc_section_shading_lower.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     opacity: .1;
//     filter: alpha(opacity=10);
//   }
// }

.previewContainer {
  width: 100%;
  max-height: 500px;
  border-radius: 2px 2px 0 0;
  background-clip: content-box;
  border-bottom: 2px dashed map-get($colors, gray600);
  padding: 0 10px;
  margin: 0 -10px;
  overflow: hidden;
}

.preview {
  width: 100%;
  display: block;
}

.settingsContainer {
  margin-bottom: 40px;
}

.brandStyleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonContainer {
  margin-top: 40px;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .colL {
    flex-direction: row;
    margin-top: 0;
  }
  .brandStyleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.grid {
  margin-left: -10px;
  margin-right: -10px;
}

.cell {
  width: 33.3333%;
  padding: 10px;
  display: inline-block;
}

.card {
  background: white;
  height: 390px;
  padding: 30px;
}

.animatedBackground {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: map-get($colors, gray800);
  background: linear-gradient(to right, #ededed 8%, #dddddd 18%, #ededed 33%);
  background-size: 800px 104px;
  height: 330px;
  position: relative;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.backgroundMasker {
  background: map-get($colors, white);
  position: absolute;
  &.previewTop {
    top: 0;
    left: 0;
    right: 0;
    height: 38px;
  }
  &.previewBottom {
    top: 204px;
    left: 0;
    right: 0;
    height: 82px;
  }
  &.headerRight {
    top: 286px;
    left: 170px;
    right: 0;
    height: 20px;
  }
  &.headerBottom {
    top: 306px;
    left: 0;
    right: 0;
    height: 4px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

span.fauxLink {
  color: map-get($colors, blue400);
}

.uploadContainer {
  position: relative;
  height: 132px;
  margin: 24px;
  border: 1.5px dashed map-get($colors, gray600);
  border-radius: 6px;
  background-color: map-get($colors, white);
  &.active {
    border: 2px dashed map-get($colors, blue500);
    color: map-get($colors, blue500);
  }
  cursor: pointer;
  &:hover {
    background-color: map-get($colors, gray900);
    span.fauxLink { // light up the fake hyperlink, since clicking anywhere will activate it
      color: map-get($colors, blue500);
    }
  }
}

.placeholderImage {
  background-image: url($cdnAssetsUrl + '/hopper/imgs/gallery_drop_bg.png');
  background-repeat: repeat-x;
  padding: 4px;
  background-clip: content-box;
  text-align: center;
  height: 100%;
  div {
    margin-top: 45px;
  }
}

.dropzoneOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .1s, transform .1s;
  background-color: map-get($colors, blue500);
  opacity: 0;
  z-index: -1;
  transform: scale(.95);
  &.active {
    transform: scale(1);
    opacity: .25;
    z-index: inherit; // goes above and prevents interactions with thumbs
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
// For mobile phones
.filters {
  display: flex;
  width: calc(100% + 144px);
}

/* For desktop: */
@media only screen and (min-width: 400px) {
  .filters {
    width: 100%;
  }
}

// For mobile phones
.posABFull {
  position: absolute;
  width: 100%;
  height: 100%;
}

.posFull {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.posCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.posHorzCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.posVertCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pageContainer {
  padding-bottom: 90px;
}

/* For tablets and Desktop: */
// Allows us to support sticky header on desktops which doesn't work with overflow hidden
@media only screen and (min-width: 904px) {
  .posFull {
    overflow: visible;
  }
}

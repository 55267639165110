$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.componentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 40px 0;
}

.dropzone {
  height: 96px;
  margin: 24px 0;
  background: map-get($colors, gray800);
  border: 1px dashed map-get($colors, gray600);
  box-shadow: none;
  border-radius: 6px;
  transition: .2s;
  &:hover {
    box-shadow: 0 0 0 6px rgba(map-get($colors, blue400), .2);
    cursor: pointer;
  }
}

.dzButton {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 24px 0;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid map-get($colors, gray700);
  &.loading {
    background: url($cdnAssetsUrl + '/themes/' + $vendor + '/square_spinner.gif') center center no-repeat;
  }
}

.img {
  max-height: 64px;
  max-width: 304px;
  vertical-align: middle;
}

.buttonContainer {
  display: inline-flex;
}

.button {
  margin-right: 16px;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .componentWrapper {
    width: 320px;
    margin: 0 80px 0 0;
  }
}

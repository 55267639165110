$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';
//For Mobile Phones
.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  background-color: map-get($colors, white);
  overflow: hidden;
}

.topSection {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 32px;
}

.heading {
  // margin-bottom: 24px;
  padding: 0 48px 16px 0;
  margin: 0;
  // border-bottom: 1px solid map-get($colors, gray700);
}

.collectionName {
  margin-bottom: 24px;
  height: 48px;
}

.message {
  font-size: 14px;
  margin: 0 0 8px 0;
}

.selectWrapper {
  width: 100%;
  position: relative;
}

.select {
  appearance: none;
  height: 40px;
  width: 100%;
  margin-bottom: 24px;
  padding: 4px 32px 3px 8px;
  font-size: 18px;
  color: map-get($colors, gray100);
  background-color: map-get($colors, white);
  border: 1px solid map-get($colors, gray600);
  border-radius: 6px;

  & > option {
    text-transform: capitalize;
  }
}

.dropdownArrow {
  position: absolute;
  right: 12px;
  top: 13px;
  pointer-events: none;
  height: 14px;
  width: 14px;
  fill: map-get($colors, gray400);
}

label.setAsDefault {
  align-self: flex-start;
  cursor: pointer;

  & > input {
    height: 18px; /* To match text size. */
    width: 18px; /* To match text size. */
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
  }

  & .text {
    /* The Text component continues to be difficult/annoying to work with. */
    display: inline;
    margin: 0 0 0 8px;
    vertical-align: middle;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  // justify-content: space-between;
  padding: 32px;
  border-top: 1px solid map-get($colors, gray700)
  // background-color: map-get($colors, white);

  /* TODO: Implement rounded shadow. */
  // box-shadow: 0 -1px 3px 0px rgba(0, 0, 0, .1);
}

// .button {
//   display: inline-block;
//   width: initial;
//   min-width: initial;
//   margin: 0;
//   padding-right: 20px;
//   padding-left: 20px;
//   font-size: 18px;
// }

// For desktop
@media only screen and (min-width: 600px) {
  .container {
    width: 400px;
    height: auto;
    max-height: 520px;
    border-radius: 12px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  padding: 20px 30px 8px 30px;
  user-select: none;
}

.orientationItem {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 12px;
  cursor: pointer;
}

.input {
  cursor: pointer;
}

.label {
  margin: 0px 5px;
  color: map-get($colors, black);
}

.foilIcon {
  width: 53px;
  height: 53px;
  opacity: .6; // External assets are black, so use opacity on white background to make them gray.
  margin-right: 5px;
  padding: 0px 1px; // Safari crops SVGs that get too close to the edge!
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.container {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: 100%;
    width: 100%;
  }
}

.foilBadge {
  top: 0;
  right: 0;
}

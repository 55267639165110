$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

/* For mobile phones: */
.markUpType {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.paperOfferings {
  padding-top: 48px;
  padding-bottom: 40px;
}

.markUpPreview {
  margin-top: 24px;
}

.buttonContainer {
  padding-top: 50px;
  padding-bottom: 40px;
}

.warningContainer {
  display: flex;
  justify-content: flex-end;
}

.warning {
  display: flex;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .markUpType {
    flex-direction: row;
  }
  .markUpPreview {
    margin-top: 40px;
  }
}

@import '../utils/utils';

// Body font
:global {
  body {
    font-family: map-get($fonts, paraFamily);
  }
}

// (for some reason @font-face only works when outside of ':global')
// WHCC fonts
@if $vendor == whcc {
  @include font-face(
    webfontregular,
    $cdnAssetsUrl + '/themes/' + $vendor + '/fonts/whitehousecustom-medium-webfont',
    normal,
    normal
  );
}

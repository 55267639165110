$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: map-get($colors, gray900);
  cursor: pointer;
}

.default {
  border: .5px solid map-get($colors, gray700);
  padding: 4px 4px;
  border-radius: 4px;
  // box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, .05);

  &:hover {
    .modalArrow {
      color: map-get($colors, gray100);
    }
  }
  // &:hover, &.open {
  //   background-color: map-get($colors, gray800);
  // }
}

.modal {
  border: none;
}

.selectBtnLabel {
  text-align: center;
  user-select: none;
}

.centeredIconLabel {
  padding: 0;
  text-align: center;
  width: 100%;
  height: 100px;
  opacity: .5;
  pointer-events: none;
}

.centeredLabel {
  position: relative;
  bottom: 20px;
  font-size: 16px;
  font-weight: 300;
}

.form {
  padding: 9px 30px 9px 12.5px;
}

.arrow {
  color: map-get($colors, gray300);
  margin-top: 5px;
}

.modalArrow {
  color: map-get($colors, gray600);
  margin-right: 2px;
  margin-top: 4px;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';
$brandingHeight: 212px;

.componentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 40px 0;
}

.input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

.brandingPreviewWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top: 1px solid map-get($colors, gray700);
  margin-top: 24px;
}

.brandingPreview {
  margin-top: -320px;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .brandingPreviewWrapper {
    width: 320px;
    margin: 0;
  }
  .componentWrapper {
    flex-direction: row;
    width: 712px;
    margin: 0;
  }
  .input {
    width: 320px;
    margin: 0 80px 0 0;
  }
  .brandingPreview {
    transform: scale(0.8);
  }
}

// Shared colors
$colors: (
  //black + white
  black: #000000, //used for shadows mostly, should be avoided
  white: #FFFFFF,

  //neutrals
  gray100: #302D2F,
  gray300: #626466,
  gray400: #828688,
  gray600: #D4D6D8,
  gray700: #E7E8E9,
  gray800: #F5F5F5,
  gray900: #F8f8f8,

  //blues
  blue100: #051F42,
  blue300: #064BAC,
  blue400: #0E5FD3, //new
  blue500: #3085FD,
  blue800: #D2E4FE,

  //validative
  green300: #308801,
  orange300: #E4660C,
  red300: #D40000,
  red600: #F6E3E3, //delete button pale hover state

  // DO NOT USE THESE COLORS. They are in the process of being phased out.
  // =========================================================================
  altBlue: #1A92EA, // Only on account box in website nav area
  cardTitle: #777777, // Only on account box in website nav area
  lightGray: #E6E6E6, // Only on account box in website nav area

  //Oldschool Colors for website nav - delete when we have new nav
  gray0: #191919,
  gray1: #302D2F,
  gray2: #626466,
  gray3: #82878A,
  gray4: #D4D4D4,
  gray4h: #E7E8E9, // 'h' - highlight variant
  gray5: #F5F5F5,
  gray6: #FBFBFB,
  blue1: #00106D,
  blue2: #0a257b,
  blue3: #004496,
  blue5: #0975C6,
  blue7: #0687Df,
  blue8: #34A4F6,
  blue9: #CEE7F8,
  green1: #9eb632,
  green2: #749716,
  green3: #79BD20,
  orange1: #FF9F1A,
  red1: #D40000,
  red2: #800009,
  red3: #A64373,
);

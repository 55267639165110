$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.active {
  background-color: map-get($colors, gray800); // TODO: allow this to be turned off
  cursor: pointer;
}

.list * {
  pointer-events: none; // to stop children from interferring with hover states
}

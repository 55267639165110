$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.familyName {
  text-transform: capitalize;
}

.inputLabel {
  position: absolute;
  right: 96px;
  top: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  vertical-align: middle;
  margin: 0 0 -4px 8px;
}

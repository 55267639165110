$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';
$assetPath: $cdnAssetsUrl + '/themes/' + $vendor;

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--vw-xxl);
  margin: 0 auto 32px auto;
  &:not(:first-of-type) {
    padding-top: 40px;
  }
}

.warning {
  float: right;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .section {
    flex-direction: row;
  }
}

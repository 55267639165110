$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';
@import '../text/Text.scss';

$inputDefaultLineHeight: 24px;
$inputDefaultMinHeight: 56px;

.default {
  border: 1px solid map-get($colors, gray600);
  border-radius: 6px;
  color: map-get($colors, defaultInputText);
  font-family: $paraFamily;
  font-size: 18px; //body1
  line-height: $inputDefaultLineHeight;
  margin: 10px 0 20px;
  min-height: $inputDefaultMinHeight;
  width: 100%;
  resize: vertical;
}

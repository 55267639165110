$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.section {
  border: 0.5px solid map-get($colors, gray600);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 20px;
  cursor: pointer;
}

.active {
  background: map-get($colors, gray900);
}

.text {
  color: map-get($colors, gray100);
}

.label {
  margin-bottom: 9px;
  color: map-get($colors, gray100);
  font-size: 18px;
}

.selectWrapper {
  border-radius: 6px;
  border: 1px solid map-get($colors, gray600);
  position: relative;
}

.select {
  font-size: 18px;
  color: map-get($colors, gray100);
  letter-spacing: -0.03em;
  padding: 3px 30px 3px 9px;
  background-color: map-get($colors, white);
  height: 40px;
  border-radius: 6px;
  width: 100%;
  border: none;
  appearance: none;
}

.dropdownArrow {
  position: absolute;
  right: 12px;
  top: 13px;
  pointer-events: none;
  height: 14px;
  width: 14px;
  fill: map-get($colors, gray600);
}

.container {
  // margin: 0 0 14px 0;
}

.link {
  color: map-get($colors, blue400);
}

.shipToStudioDetails {
  display: flex;

  .shippingInfo {
    color: map-get($colors, gray100);
    flex: 1 0 0;
    font-size: 18px;
    white-space: pre-line;
    overflow-wrap: break-word;
  }

  .contactInfo {
    flex: 1 0 0;
    margin-left: 20px;
  }
}

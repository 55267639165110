$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.section {
  border: .5px solid map-get($colors, gray600);
  border-radius: 2px;
  padding: 20px;
}

.margin {
  margin: 20px 0;
}

.clickable {
  cursor: pointer;
  & .title {
    margin-bottom: 0;
  }
}

.header {
  overflow: hidden;
}

.title {
  float: left;
  margin-bottom: 7px;
  transition: .5s cubic-bezier(.215, .61, .355, 1);
}

.siToggle {
  float: right;
  position: relative;
  width: 15px;
  height: 15px;
  margin: 3px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: map-get($colors, gray400);
    transition: transform .25s ease-out;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    margin-left: -.5px;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    margin-top: -.5px;
  }
}

.open {
  &:before {
    transform: rotate(135deg);
  }
  &:after {
    transform: rotate(135deg);
  }
}

.instructions {
  max-height: 0;
  overflow: hidden;
  transition: .5s;
  &.isExpanded {
    max-height: 1000px;
  }
  textarea {
    padding: 12px;
    color: map-get($colors, gray100);
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  padding: 0 0 12px 0;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: map-get($colors, white);
  background-image: url($cdnAssetsUrl + '/hopper/imgs/earnings_bg.png');
  background-size: cover;
  border-radius: 4px;
  min-height: 60px;
  & > div {
    margin-bottom: 0;
  }
}

.text {
  color: map-get($colors, white);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

// For mobile phones
.container {
  width: 100vw;
  height: 100vh;
  padding: 0 24px 96px 24px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
  top: 0;
  background-color: map-get($colors, white);
  border-radius: 0;
}

.body {
  padding: 0;
}

.linkDetails {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  padding: 16px 0 24px 0;
  border-bottom: 1px solid map-get($colors , gray700);
  &:last-child {
    margin-bottom: 0;
  }
}

.linkSettings {
  color: map-get($colors , gray300);
  &:hover {
    color: map-get($colors , blue400);
  }
}

.details {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  stroke: map-get($colors , gray100);
}

// For desktop
@media only screen and (min-width: 600px) {
  .container {
    border-radius: 12px;
    width: 600px;
    padding: 0 40px 96px 40px;
    height: 90vh;
    top: 0;
    max-height: 680px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.container {
  padding: 20px;
  font-size: 12px;
  line-height: 13px;
  color: map-get($colors, gray100);
  width: 290px;
}

.link {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #0687df;

  &:hover {
    text-decoration: underline;
  }
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: #0051b6;
  border-bottom-color: #0088e6;
  transform: rotate(-45deg);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.item {
  margin: 0 0 8px 0;
  padding: 0 16px;
  border-radius: 6px;
  transition: .1s;
  height: 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: map-get($colors , gray800 );
    .chevron {
      margin-right: -8px;
    }
  }
}

.icon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  &.product {
    opacity: .8;
  }
}

.wrapper {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.text {
  margin: 0;
}

.chevron {
  fill: map-get($colors, gray100);
  align-self: center;
  margin-right: -4px;
  width: 16px;
  height: 16px;
  transition: .1s;
}

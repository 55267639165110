$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.text {
  display: contents;
  width: 100%;
  vertical-align: middle;
  padding-top: 1px; // Fudge factor to get text to line up with center of radio
  transition: .16s;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';
@import './_FormIcon';

$circleLength: 8px;

.active .circleContainer {
  opacity: 1;
}

.circleContainer {
  position: absolute;
  left: $iconLength / 2 - $circleLength / 2;
  top: $iconLength / 2 - $circleLength / 2;
  width: $iconLength;
  height: 100%;
  opacity: 0;
  transition: .15s;
}

.circle {
  width: $circleLength;
  height: $circleLength;
  background: map-get($colors, white);
  border-radius: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: $iconLength;
  height: $iconLength;
  border-radius: 100%;
  transition: .15s;
}

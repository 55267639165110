$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.page {
  background-color: map-get($colors, white);
  min-height: 800px;
  max-width: var(--vw-xxl);
  margin: 0 auto;
}

.header {
  box-shadow: none;
  margin-bottom: 0;
}

.title {
  width: 656px !important;
}

.uploadMetadata {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.galleryImages {
  height: 100%;
  position: relative;
}

//for the gallery photo label to line up with header
@media only screen and (min-width: 904px) {
  .uploadMetadata {
    margin-left: 74px;
  }
}

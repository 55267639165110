$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.checkboxContainer {
  display: inline-block;
  cursor: pointer;
}

.icon {
  fill: none;
  stroke: map-get($colors, white);
  stroke-width: 2px;
}

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
.hiddenCheckbox {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.styledCheckbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: map-get($colors, gray600);
  border-radius: 4px;
  // transition: all 150ms;

  .hiddenCheckbox:focus + & {
    box-shadow: 0 0 0 2px map-get($colors, blue500);
  }

  .icon {
    visibility: hidden;
  }

  // Checked
  &.checked {
    background: map-get($colors, blue400);
  }

  &.checked .icon {
    visibility: visible;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';
@import '../styles/utils/zIndex';

/* For mobile phones: */
.container {
  padding-bottom: 30px;
}

.stickyHeader {
  position: sticky;
  position: -webkit-sticky; //safari
  top: 0;
  z-index: map-get($zIndex, stickyHeader); // keep this on top of everything
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

.title {
  width: 100%!important;
}

.main {
  position: relative;
}

// /* For desktop: */
// @media only screen and (min-width: 904px) {

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.sidebarItem {
  margin-bottom: 8px;
  width: 100%;
}

.headerDescription {
  text-transform: capitalize;
}

.scrollable {
  height: auto;
  margin-right: -32px; /* To move the scrollbar off the content. */
  padding-top: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 224px;
}

.hr {
  border: 0;
  height: 0;
  margin-bottom: 0;
}

.startingAt {
  margin: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.select {
  --arrow-height: 8px;
  --select-height: 40px;
  --select-padding: 8px;

  position: relative;

  & > select {
    font-size: 16px; //body1
    font-family: map-get($fonts, paraFamily);
    color: map-get($colors, gray100);

    height: var(--select-height);
    width: 200px;

    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 6px;

    padding: 0 var(--select-padding);
    appearance: none;
    background: map-get($colors, gray900);
  }

  &:after {
    content: '';

    position: absolute;
    right: var(--select-padding);
    top: calc(var(--select-height) / 2 - var(--arrow-height) / 2);

    border-left: var(--arrow-height) solid transparent;
    border-right: var(--arrow-height) solid transparent;
    border-top: var(--arrow-height) solid map-get($colors, gray600);
  }
}

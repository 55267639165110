$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';
@import './InputTextbox';

.inputTextbox {
  width: 320px !important;
  font-size: 15px;
  border-width: 1px;
  opacity: 1; /* required on iOS to override default disabled style */
  border-color: transparent;
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: -$inputPadding;
  transition-duration: 0.2s;
  transition-property: background-color, border-color;

  // Font Styles
  font-weight: 500;
  -webkit-text-fill-color: map-get($colors, gray100); // override safari disabled input text color

  &:not([disabled]) {
    &:invalid,
    &:hover,
    &:focus {
      background-color: map-get($colors, gray800);
      border-color: map-get($colors, gray600);
    }
  }
}

/* For tablets: */
@media only screen and (min-width: 540px) {
  .inputTextbox {
    font-size: 17px;
    width: 400px !important;
  }
}

/* For desktop: */
@media only screen and (min-width: 648px) {
  .inputTextbox {
    font-size: 24px; //h1
    width: 464px !important;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.icon {
  color: map-get($colors, white);
  width: 36px;
  height: 36px;
  background: linear-gradient( 90deg, map-get($colors, gray600) 50%, map-get($colors, gray600) 50% ); // TODO: Move into main component so theme colors can be applied
  text-align: center;
  line-height: 36px;
  border-radius: 100%;
  margin-right: 10px;
  display: inline-block;
  &.shared {
    line-height: 32px;
  }
}

.shareIcon {
  background: map-get($colors, white);
  background-color: map-get($colors, white);
  color: map-get($colors, gray400);
  border-color: map-get($colors, gray400);
  border-width: 1px;
  border-style: solid;
}

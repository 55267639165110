$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

// For mobile phones
.gallery {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 5px;

  &:after {
    /* Ensures the last photo doesn't get stretched */
    content: '';
    flex-grow: 1000;
  }
}

.buttonContainer {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.item {
  margin: 5px;
  flex-grow: 1;

  &:hover .itemOverlay {
    opacity: .9;
    z-index: map-get($zIndex, photoGrid);
  }
}

.itemOverlay {
  display: none;
}

.iconContainer {
  width: 24px;
  stroke: map-get($colors, gray100);
  stroke-width: 2px;
  transition: .2s;
}

// For desktop
@media only screen and (min-width: 648px) {
  .itemOverlay {
    position: absolute;
    display: block;
    width: 64px;
    height: 40px;
    bottom: 8px;
    right: 8px;
    border-radius: 6px;
    background: map-get($colors, white);
    opacity: 0;
    transition: opacity .2s;
    &:hover {
      background: map-get($colors, gray800);
    }
  }
}

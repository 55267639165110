$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.contentContainer {
  padding: 0;
  max-width: 1528px;
  margin: auto;
}

.details {
  margin: 0 auto;
  background-color: map-get($colors, white);
}

.detailsHeader {
  overflow: hidden;
}

.designsContainer {
  box-shadow: inset 0 2px 2px 0 map-get($colors, white);
  background-color: map-get($colors, gray900);
  &.unbranded {
    background-color: map-get($colors, white);
  }
}

.allDesigns {
  margin-top: 80px;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

//For mobile phones
.container {
  margin: 0 8px 8px 0;
  display: block;
  float: left;
  line-height: 0;
  position: relative;
  width: auto;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.2s transform 0.15s cubic-bezier(0.43, 0, 0.63, 1);
  &:hover .photoOverlay {
    opacity: 0.9;
    z-index: map-get($zIndex, photoGrid);
  }
}

.photoOverlay {
  display: none;
}

.thumb {
  background: map-get($colors, gray800);
  .img {
    transition: 0.2s cubic-bezier(0.43, 0, 0.63, 1);
    opacity: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.progress {
  width: 16px;
  height: 16px;
  opacity: 1;
  transition: opacity 0 0.1s;
}

.iconContainer {
  z-index: 10;
  width: 30px;
  opacity: 1;
  fill: none;
  stroke: map-get($colors, gray100);
}

.garbageCan {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  fill: none;
  stroke: map-get($colors, red300);
}

.photoText {
  background: map-get($colors, gray300);
  padding: 5px 13px;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: default;
}

.text {
  color: map-get($colors, white);
}

/* For tablets and Desktop: */
@media only screen and (min-width: 648px) {
  .photoOverlay {
    display: initial;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: map-get($colors, white);
    opacity: 0;
    transition: 0.2s;
  }
}

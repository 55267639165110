$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.disabled {
  pointer-events: none;
}

.navItem {
  display: inline-block;
  position: relative;
  height: 32px;
  cursor: pointer;
  margin-right: 40px;
  white-space: nowrap;
  &.active {
    border-bottom: 2px solid map-get($colors, blue400);
  }

  &:last-child {
    margin-right: 0;
  }

  &.active .primaryLabel, &:hover:not(.disabled) .primaryLabel {
    color: map-get($colors, gray100);
  }

  &.active .secondaryLabel, &:hover:not(.disabled) .secondaryLabel {
    color: rgba(255, 0, 0, 0.8);
  }

  &.active .navIcon, &:hover:not(.disabled) .navIcon {
    stroke: map-get($colors, gray100);
    stroke-width: 1.5px;
  }
}

.navLink {
  display: inline-block;
}

.navIcon {
  display: inline-block;
  height: 24px;
  width: 24px;
  vertical-align: middle;
  stroke: map-get($colors, gray400);
  transition: .1s;
}

.label {
  display: inline-block;
  width: auto;
  margin-left: 8px;
  vertical-align: middle;
  font-weight: 400;
}

.secondaryLabel {
  color: rgba(255, 0, 0, 0.4);
  font-size: 8px;
  transition: .1s;
}

.primaryLabel {
  color: map-get($colors, gray400);
  margin-bottom: 0;
  transition: .1s;
}

.chevron {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  fill: map-get($colors, gray600);
  height: 18px;
  width: 18px;
  position: relative;
}

.completeCheck {
  position: absolute;
  right: -12px;
  top: 25%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.completeCheckIcon {
  stroke: map-get($colors, green300);
  stroke-width: 2px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

// For mobile phones
.sidebarItem {
  display: block;
}

.pricingWrapper {
  display: block;
}

.sidebarBtn {
  margin-bottom: 12px;
  width: 100%;
}

.pricingGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;
  padding: 12px 0;
  border-top: 1px solid rgba(map-get($colors, gray700), 1);

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:first-child {
    border-top: none;
  }

  * {
    margin-bottom: 0;
  }
}

.total {
  color: map-get($colors, gray100);
}

.quantitySelect {
  min-width: 74px;
  border: 1px solid map-get($colors, gray600);
  border-radius: 4px;
  text-align: left;
}

.quantityInput {
  margin-top: 0;
  width: 45px;
  height: 21px;
  text-align: right;
  font-size: 16px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid map-get($colors, gray600);
}

.disableLink {
  pointer-events: none;
}

.checkMark {
  position: absolute;
  left: 20px;
  width: 20px;
  height: 15px;
}

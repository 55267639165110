:root {
  --vw-xs: 375px;
  --vw-sm: 600px;
  --vw-md: 875px;
  --vw-lg: 1200px;
  --vw-xl: 1440px;
  --vw-xxl: 1700px;

  --font-family-display: 'Beatrice', sans-serif;
  --font-family-body: 'Untitled', Helvetica, Arial, sans-serif;
  --font-family-hand: 'Rock Salt', cursive;

  --hor-space: 1rem;

  @media (min-width: 600px) {
    --hor-space: 1.5rem;
  }

  @media (min-width: 1200px) {
    --hor-space: 2rem;
  }

  @media (min-width: 1440px) {
    --hor-space: 2.5rem;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

// For mobile devices
.container {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  overflow-y: auto;
}

.content {
  padding: 30px;
  background-color: map-get($colors, white);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  border-bottom: 1px solid map-get($colors, gray700);
  padding: 0 0 24px 0;
  margin: 0 0 24px 0;
}

.sizeSelectionGroup {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
  gap: 8px;
}

.duplicateSizeSelectionGroup {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 8px;
}

.radioInput {
  visibility: hidden;
}

.checkBadge {
  height: 16px;
  width: 16px;
  fill: map-get($colors, blue400);
  top: -4px;
  right: -4px;
  position: absolute;
}

.warnBadge {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  fill: map-get($colors, orange300);
  top: 1px;
  position: relative;
}

.sizeSelection {
  display: flex;
  height: 56px;
  align-items: center;
  border-radius: 6px;
  position: relative;
  overflow: visible;
  border: 1px solid map-get($colors, gray600);
  transition: 0.2s;
  cursor: pointer;
  &:hover,
  .highlight:hover {
    box-shadow: 0 0 0 6px map-get($colors, blue800);
  }
  &:last-of-type {
    margin-right: 0;
  }
  &.disabled {
    opacity: 0.3;
  }
}

.sizeSelectionContent {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: map-get($colors, white);
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 6px; // avoids white background clipping corners of border on parent
  transition: 0.2s;
}

.highlight {
  border-radius: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  &.active {
    box-shadow: 0 0 0 6px map-get($colors, blue800);
  }
}

.sizeIconContainer {
  margin-right: 8px;
}

.sizeIcon {
  stroke: map-get($colors, gray100);
  fill: none;
}

.selectionSection {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.selectionPaperTypes {
  flex-grow: 3;
  margin-right: 40px;
}

.selectionSpreads {
  flex-grow: 1;
}

.selectWrapper {
  width: 100%;
  position: relative;
}

.select {
  appearance: none;
  height: 40px;
  width: 100%;
  margin-bottom: 14px;
  padding: 3px 30px 3px 9px;
  font-size: 18px; //body1
  color: map-get($colors, gray100);
  background-color: map-get($colors, white);
  border: 1px solid map-get($colors, gray600);
  border-radius: 6px;
  text-transform: capitalize;
  & > option {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-content: space-between;
  }
}

.dropdownArrow {
  position: absolute;
  right: 12px;
  top: 13px;
  pointer-events: none;
  height: 14px;
  width: 14px;
  fill: map-get($colors, gray600);
}

.actionContainer {
  display: flex;
  padding: 32px;
  border-top: 1px solid map-get($colors, gray700);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cloneContainer {
  justify-content: flex-end;
}

.cloneSize {
  margin-bottom: 24px !important;
}
.actionContainerText {
  color: map-get($colors, gray400) !important;
}
.ratioLabel {
  color: map-get($colors, gray400) !important;
  margin-top: 24px;
  margin-bottom: 8px !important;
}

// For desktop
@media only screen and (min-width: 600px) {
  .container {
    width: 640px;
    height: auto;
    max-height: calc(100vh - 40px);
    border-radius: 12px;
    overflow-y: auto;
  }
}

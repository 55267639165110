$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';
@import '../../../styles/utils/zIndex';

$card-margin: 32px;

/* For mobile phones: */
.card {
  position: relative; // So ::after works
  display: inline-block;
  vertical-align: top;
  margin-bottom: $card-margin;
  height: 320px;
  min-width: 320px;
  width: 100%;
  // transition: .2s;
  transform-origin: 50% 50%;
  border-radius: 6px;
  background-color: map-get($colors, gray900);
}

.checked {
  background-color: map-get($colors, blue800);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

// Use an ::after pseudo-element to show the box-shadow on :hover.
// The transition of box-shadow wasn't working in Safari.
// As a bonus, this is more performant because is transitions on opacity
// instead of directly on box-shadow.
.card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px rgba(map-get($colors, black), 0.2);
  opacity: 0;
  // transition: opacity .2s;
  pointer-events: none;
}

.card:hover {
  opacity: 1;
  // border: 1px solid map-get($colors, gray600);
  box-shadow: 0 2px 4px rgba(map-get($colors, black), 0.2);
}

/* For desktop: */
@media only screen and (min-width: 720px) {
  .card {
    width: calc(50% - #{$card-margin} / 2);
    margin-right: $card-margin;
    &:nth-child(2n) {
      margin-right: 0px;
    }
  }
}

@media only screen and (min-width: 1070px) {
  .card {
    width: calc((100% / 3) - (#{$card-margin} * 2) / 3);
    margin-right: $card-margin;
    &:nth-child(2n) {
      margin-right: $card-margin;
    }
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

@media only screen and (min-width: 1430px) {
  .card {
    width: calc((100% / 4) - (#{$card-margin} * 3) / 4);
    margin-right: $card-margin;
    &:nth-child(3n) {
      margin-right: $card-margin;
    }
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.componentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 40px 0;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .componentWrapper {
    width: 712px;
    margin: 0;
  }
}

@import '../../../styles/utils/utils';

// // TODO: These will be theme-level
// $paraFamily: 'Helvetica Neue', Helvetica, Arial, sans-serif;
// $paraFamilyLight: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// NEW 2021 STYLES

//Header
.h1 {
  font-family: var(--font-family-display);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 8px;
  &.shared {
    font-family: map-get($fonts, paraFamily);
  }
}

.h2 {
  font-family: var(--font-family-display);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  &.shared {
    font-family: map-get($fonts, paraFamily);
  }
}

.h3 {
  font-family: var(--font-family-display);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 8px;
  & > a {
    color: map-get($colors, blue400);
  }
  &.shared {
    font-family: map-get($fonts, paraFamily);
    margin-bottom: 12px;
  }
}

//Body
.body1 {
  font-family: var(--font-family-body);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  // line-height: 24px;
}

.body2 {
  font-family: var(--font-family-body);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.body2b {
  font-family: var(--font-family-body);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.body3 {
  font-family: var(--font-family-body);
  color: map-get($colors, gray100);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.badge {
  font-family: var(--font-family-body);
  color: map-get($colors, white);
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
}

// Noting other places where one-offs exist:
// - NativeSelect.scss collections sort order dropdown text
// - CollectionDetails.scss selected design count
// - InputTexbox which goes into EditableHeader (project title, modal titles)
// - TextArea.scss form element
// - AlbumCreation.scss modal dropdown text
// - Button.scss

// Temporary
// - LandingCollection fonts - will get removed with new website
// - Submit button on Checkout - will get removed with platform checkout

//=========================================================================
// Disconnected in Text.jsx
// .text {
//   line-height: 1.2;
//   margin-bottom: 7px;
//   &:only-child {
//     margin-bottom: 0px;
//   }
// }

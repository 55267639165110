$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';
@import '../../../styles/utils/zIndex';

$card-margin: 32px;

/* For mobile phones: */
.card {
  display: inline-block;
  vertical-align: top;
  height: 416px;
  min-width: 280px;
  backface-visibility: hidden;
  margin-bottom: $card-margin;
  width: 100%;
}

.content {
  position: relative; // So ::after works
  transform-origin: 50% 50%;
  border-radius: 6px;
}
//brings content over top of cards on hover
.card:hover .content {
  z-index: map-get($zIndex, cardHover);
}

// Use an ::after pseudo-element to show the box-shadow on :hover.
// The transition of box-shadow wasn't working in Safari.
// As a bonus, this is more performant because is transitions on opacity
// instead of directly on box-shadow.
.content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 280px;
  height: 100%;
  opacity: 0;
  border-radius: 6px;
  pointer-events: none;
}

.content:hover.content::after {
  opacity: 1;
}

/* For desktop: */
@media only screen and (min-width: 668px) {
  .content::after {
    box-shadow: 0 0 0 8px white,
      0 2px 4px 8px rgba(map-get($colors, black), 0.2); //first shadow adds white border
  }
}

@media only screen and (min-width: 951px) {
  .card {
    width: calc(50% - #{$card-margin} / 2);
    margin-right: $card-margin;
    &:nth-child(2n) {
      margin-right: 0px;
    }
  }
}

@media only screen and (min-width: 1285px) {
  .card {
    width: calc((100% / 3) - (#{$card-margin} * 2) / 3);
    margin-right: $card-margin;
    &:nth-child(2n) {
      margin-right: $card-margin;
    }
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

@media only screen and (min-width: 1611px) {
  .card {
    width: calc((100% / 4) - (#{$card-margin} * 3) / 4);
    margin-right: $card-margin;
    &:nth-child(3n) {
      margin-right: $card-margin;
    }
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  display: flex;
  overflow: hidden;
  user-select: none;
}

.columnContainer {
  padding: 20px 30px 8px 30px;
  border-right: 1px solid map-get($colors, gray600);
  &:last-child {
    border-right: 0px;
  }
}

.option {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 12px;
  cursor: pointer;
}

.input {
  cursor: pointer;
}

.icon {
  height: 53px;
  width: 53px;
  margin-right: 5px;
}

.label {
  margin: 0px 5px;
  color: map-get($colors, gray100);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

// For mobile
.sidebarHeading {
  height: 32px;
  border-bottom: 1px solid map-get($colors, gray700);
}

.groupingHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-self: stretch;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0 0 0;
}

.productsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.newCollection {
  width: calc(100% + 8px);
  margin: 4px 0 0 -8px;
}

.illustration {
  width: 40px;
  height: 40px;
}

.mobileBadge {
  position: relative;
  width: fit-content;
  left: 148px;
  top: 44px;
  color: map-get($colors , white);
  border-radius: 4px;
  background-color: map-get($colors , gray400);
  padding: 1px 4px 2px 4px;
}

/* For desktop: */
@media only screen and (min-width: 648px) {
  .mobileBadge {
    display: none;
  }
  .newCollection:hover {
    background-color: map-get($colors , gray800);
    border-radius: 4px;
  }
}

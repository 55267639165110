$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

$compactHeight: 146px;
$leftMargin: 30px;
$rightMargin: 500px;
$width: 100%;

.container {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 80px;
  background: map-get($colors, white);
  &.compact {
    height: $compactHeight;
  }
}

.actionContainer {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 12px;
  background: map-get($colors, white);
  position: relative;
  z-index: map-get($zIndex, photoGrid);
}

.photoGridLink {
  cursor: pointer;
  font-weight: bold;
  margin: 0 0 0 8px;
  &.default {
    position: absolute;
    line-height: 22px;
  }
}

.photos {
  width: $width;
  display: table;
  position: relative;
  &.compact {
    display: inline-flex;
    position: absolute;
  }
}

.photosHiddenWrapper {
  height: auto;
  width: 100%;
  &.compact {
    overflow: hidden;
  }
}

.photosWrapper {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  &.compact {
    display: inline-flex;
    margin-left: 0;
    height: $compactHeight;
  }
}

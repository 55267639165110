$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.face {
  position: relative;
  max-width: 33%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 20px;
  border-right: 1px solid map-get($colors, gray700);
  cursor: pointer;
  overflow: hidden;
  transition: .1s;

  &:hover {
    background: map-get($colors, gray800);
  }

  &.active {
    background: map-get($colors, gray800);

    &::after {
      $size: 20px;
      width: $size;
      height: $size;
      background-color: map-get($colors, white);

      // Arrow pointing down at tab
      // content: '';
      // position: absolute;
      // top: -14px;
      // left: calc(50% - #{$size} / 2);
      // transform: rotate(45deg);
      // box-shadow: 1px 1px 2px 0 rgba(map-get($colors, black), 0.1);
    }
  }
}

.face:last-child:not(:nth-of-type(2)) {
  border-right: none;
}

.preview {
  height: 56px;
  width: 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    height: 100%;
    width: 100%;
  }
}

.label {
  margin-left: 12px;
}

.activeLabel {
  color: map-get($colors, gray100);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

.progressContainer {
  min-width: 0px;
  margin: 0 12px 0 0;
}

.progressText {
  color: map-get($colors, gray400);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  width: 100%;
  margin: 0px;
}

.option {
  width: auto;
  height: auto;
  margin: 0 4px 8px 4px;
  display: inline-block;
}

.highlight {
  position: absolute;
  z-index: -1;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  left: -4px;
  top: -4px;
  border-radius: 4px;
  opacity: 0;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  transition: 0.15s;
  &.active {
    opacity: 0.2;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.iconWrapper {
  position: relative;
}

.checkContainer {
  position: absolute;
  width: 16px;
  height: 16px;
  pointer-events: none;
}

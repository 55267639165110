/* stylelint-disable */
@import '../utils/utils';

.smarty-ui {
  transition: opacity .1s;
}

.smarty-suggestion {
  font-size: 18px !important;
  padding: 5px !important;
}

.smarty-autocomplete {
  border: 1px solid map-get($colors, gray600) !important;
  box-shadow: 0 2px 10px 0 rgba(map-get($colors, black), .2) !important;
}

.smarty-ui.hide {
  opacity: 0 !important;
}

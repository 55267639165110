$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.accentColor {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 96px;
  border: 1px solid map-get($colors, gray700);
  border-radius: 100px;
  text-align: center;
  margin: 32px 0;
  cursor: pointer;
}

.colorLabel {
  line-height: 40px;
}

.dark {
  color: map-get($colors, white);
}

.light {
  color: map-get($colors, gray100);
}

.componentWrapper {
  width: 100%;
}

.picker {
  position: absolute;
  margin-top: -64px;
  z-index: map-get($zIndex, modals);
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .componentWrapper {
    width: 320px;
  }
}

// Resetting forms (this is a work in progress)

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type=range]::-moz-focus-outer {
  border: 0;
}
button {
  color: inherit;
  font-family: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
  outline: none;
}
input {
  outline: none;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 260px;
  padding: 10px;
}

.iconWrapper {
  position: relative;
  margin: 10px;
  height: 43px;
  width: 60px;
  cursor: pointer;
}

.checkContainer {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.container {
  padding: 32px 40px 40px 40px;
  max-width: 400px;
}

.header {
  margin-bottom: 24px;
  padding-right: 48px;
}

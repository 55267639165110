$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

$grayBack: map-get($colors, gray800);
$card-margin: 24px;

/* For mobile phones: */
.container {
  width: 100%;
  min-width: 264px;
  // max-width: 368px;
  height: 328px;
  margin-bottom: 24px;
  display: inline-block;
  vertical-align: top;
  position: relative; // So ::after works
  border-radius: 6px;
  background-color: map-get($colors, white);
  box-shadow: 0 0 0 1px map-get($colors, gray700);
  // Use an ::after pseudo-element to show the box-shadow on :hover.
  // The transition of box-shadow wasn't working in Safari.
  // As a bonus, this is more performant because it transitions on opacity
  // instead of directly on box-shadow.
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // box-shadow: 0 2px 8px rgba(map-get($colors, black), 0.2); //shadow hover
    box-shadow: 0 0 0 6px map-get($colors, blue800); //blue outline hover
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    border-radius: 6px;
  }
  &:hover::after {
    opacity: 1;
  }
}

.contentContainer {
  position: relative;
  height: 176px;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.thumbnail {
  margin: 0;
  vertical-align: top;
  display: inline-block;
}

.threeThumbContainer {
  width: 105px;
  height: 100%;
  display: inline-block;
}

.oneThumb {
  width: 100%;
  height: 100%;
}

.threeThumb0,
.twoThumb0 {
  width: 100%; //193px to show up to 3 thumbs
  height: 100%;
  margin-right: 2px;
  background-color: $grayBack;
}

.threeThumb1 {
  width: 108px;
  height: 50%;
  margin-bottom: 2px;
  background-color: $grayBack;
}
.twoThumb1 {
  width: 108px;
  height: 175px;
}

.threeThumb2 {
  width: 108px;
  height: 50%;
  background-color: $grayBack;
}

.header {
  height: 152px;
  width: 100%;
  padding: 24px;
  overflow: hidden;
}

.title {
  height: 48px;
  margin-bottom: 24px;
}

a {
  text-decoration: none;
}

.metaSection {
  min-width: 64px;
  display: inline-block;
}

.metaText {
  color: map-get($colors, gray400);
}

.metaTextLabel {
  color: map-get($colors, gray400);
}

.badge {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

/* For desktop: */
@media only screen and (min-width: 620px) {
  .container {
    height: 280px;
    width: calc(50% - #{$card-margin} / 2);
    margin-right: $card-margin;
    &:nth-child(2n) {
      margin-right: 0px;
    }
  }
  .contentContainer {
    height: 128px;
  }
}

@media only screen and (min-width: 680px) {
  .container {
    height: 328px;
  }
  .contentContainer {
    height: 176px;
  }
}

@media only screen and (min-width: 920px) {
  .container {
    height: 280px;
    width: calc((100% / 3) - (#{$card-margin} * 2) / 3);
    margin-right: $card-margin;
    &:nth-child(2n) {
      margin-right: $card-margin;
    }
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
  .contentContainer {
    height: 128px;
  }
}

@media only screen and (min-width: 1000px) {
  .container {
    height: 328px;
  }
  .contentContainer {
    height: 176px;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    min-width: 280px;
    width: calc((100% / 4) - (#{$card-margin} * 3) / 4);
    margin-right: $card-margin;
    &:nth-child(3n) {
      margin-right: $card-margin;
    }
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    width: calc((100% / 5) - (#{$card-margin} * 4) / 5);
    margin-right: $card-margin;
    &:nth-child(4n) {
      margin-right: $card-margin;
    }
    &:nth-child(5n) {
      margin-right: 0px;
    }
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

.progressBar {
  --progressBarHeight: 4px;
  height: var(--progressBarHeight);
  width: 100%;
  background: map-get($colors, gray800);
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}

.indicator {
  height: var(--progressBarHeight);
  transition: width .2s;
}

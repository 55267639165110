$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
$paraFamily: 'Helvetica Neue', Helvetica, Arial, sans-serif;
@import '../../../styles/utils/utils';

// Global Button Styling
.button {
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 12px 16px;
  background-color: map-get($colors, gray800);
  // Font styles
  font-family: var(--font-family-display);
  font-weight: 500;
  font-size: inherit;
  text-align: center;
  line-height: 1em;
  &.disabled {
    opacity: 0.3;
    background-color: map-get($colors, gray600) !important;
    pointer-events: none !important;
    cursor: auto;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none !important;
    cursor: auto;
  }
}

.buttonIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  stroke: map-get($colors, gray100);
  stroke-width: 1.5px;
  transition: 0.2s;
}

.buttonIconWarning {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  stroke: map-get($colors, white);
}

// Main Buttons
.primary {
  & .buttonIcon {
    stroke: map-get($colors, white);
  }
}

.secondary {
  color: map-get($colors, gray100) !important;
  &:hover {
    background-color: map-get($colors, blue800) !important;
    color: map-get($colors, blue300) !important;
  }
  &:hover .buttonIcon {
    stroke: map-get($colors, blue300);
  }
}

// Really red button to alert users of final deletion
.warning {
  width: 100%;
  background: map-get($colors, red300);
  //Font Style
  font-size: 14px;
  color: map-get($colors, white);
}

// Red on hover to indicate delete action
.delete {
  color: map-get($colors, gray100) !important;
  &:hover {
    background-color: map-get($colors, red600) !important;
    color: map-get($colors, red300) !important;
  }
  &:hover .buttonIcon {
    stroke: map-get($colors, red300);
  }
}

// Icon Only Buttons
.icon {
  display: block;
  padding: 0;
  width: 40px;
  position: relative;
  background-color: white !important;
  &:hover {
    background-color: map-get($colors, blue800) !important;
  }
  & .buttonIcon {
    margin-right: 0;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke: map-get($colors, gray400);
  }
  &:hover .buttonIcon {
    stroke: map-get($colors, blue300);
  }
}

.iconDelete {
  display: block;
  padding: 0;
  width: 40px;
  position: relative;
  background-color: white !important;
  &:hover {
    background-color: map-get($colors, red600) !important;
  }
  & .buttonIcon {
    margin-right: 0;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke: map-get($colors, gray400);
  }
  &:hover .buttonIcon {
    stroke: map-get($colors, red300);
  }
}

// New Collection Button in create product list only
.newCollection {
  display: flex;
  font-size: 18px;
  font-weight: normal;
  background-color: white;
  color: map-get($colors, gray300) !important;
  padding: 8px;
  height: 32px;
}

.newCollectionIcon {
  width: 16px;
  height: 16px;
  margin-left: auto;
  stroke: map-get($colors, gray300);
  transition: 0.2s;
  stroke-width: 1.5px;
}

// Place Your Order Button Exclusive - can remove when we have platform checkout
.order {
  color: map-get($colors, white);
  width: 100%;
  height: 56px;
  font-size: 20px;
  padding: 16px;
}

/* For desktop: */
@media only screen and (min-width: 648px) {
  .newCollection {
    &:hover {
      color: map-get($colors, gray100) !important;
      background-color: map-get($colors, gray800);
    }
    &:hover .newCollectionIcon {
      stroke: map-get($colors, gray100);
      stroke-width: 2px;
    }
  }
}

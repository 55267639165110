$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.occasion {
  height: 456px;
  box-sizing: border-box;
  background-color: map-get($colors, gray800);
  border-radius: 6px;
  text-decoration: none;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
  position: relative; // So ::after works
  display: flex;
  align-items: flex-end;
  transition: background-size .4s ease-out;
  margin-bottom: 24px;

  // Use an ::after pseudo-element to show the box-shadow on :hover.
  // The transition of box-shadow wasn't working in Safari.
  // As a bonus, this is more performant because is transitions on opacity
  // instead of directly on box-shadow.
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(black, .5);
    opacity: 0;
    pointer-events: none;
    border-radius: 6px;
    transition-property: opacity;
    transition-duration: inherit;
    transition-timing-function: inherit;
  }
  &:hover::after {
    opacity: 1;
  }

  &.primary {
    width: $minWidth;

    & .textDescription {
      width: 300px;
    }
  }

  &.secondary {
    width: 458px;
    margin-right: 24px;
  }
}

.textGroup {
  position: absolute;
  left: 30px;
  bottom: 20px;
  width: 300px;
}

.textTitle {
  text-shadow: 0 3px 4px rgba(black, .1);
  display: flex;
  font-size: 36px;
  font-family: map-get($fonts, headFamily);
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  color: map-get($colors, blue2);
}

.textDescription {
  width: 180px;
  font-size: 15px;
  font-family: map-get($fonts, paraFamily);
  font-weight: normal;
  color: rgba(map-get($colors, black), .8);
  text-shadow: 0 2px 6px rgba(black, .2);
}

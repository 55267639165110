$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

//For mobile phones
.container {
  width: 96vw;
  user-select: none;
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.productListItem {
  text-transform: capitalize;
}

//For desktop
@media only screen and (min-width: 904px) {
  .container {
    width: 364px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
}

.envelope {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200px;
  height: 143px;
  transform: translate(-50%, -50%) rotate(-14deg);
}

.preview {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -100px;
  width: calc(100% - 160px);
  height: 200px;
}

.thankYouTitle {
  margin-top: 130px;
  font-weight: bold;
  color: map-get($colors, gray100);
}

.thankYouDescription {
  margin: 0px 30px;
  text-align: center;
}

.buttons {
  margin: 30px 80px 50px;
  width: 200px;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.layout {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.colWrapper {
  overflow: hidden;
}

.selections {
  width: 100%;
  margin-top: 24px;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .layout {
    display: flex;
    flex-direction: row;
  }

  .description {
    margin-right: 80px;
    width: 320px;
  }

  .selections {
    width: 320px;
    margin-top: 0;
  }
}

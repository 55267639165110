$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.overlay {
  z-index: map-get($zIndex, overlay);
  pointer-events: all;
  background-color: rgba(map-get($colors, black), .2);
  cursor: pointer;
  &.colorPicker {
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  &.hidden {
    opacity: 0;
  }
}

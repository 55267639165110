$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
/* stylelint-disable */
@import '../../styles/utils/utils';

// For mobile phones
.modal {
  background-color: map-get($colors, white);
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
  min-width: 300px;
  overflow: visible;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: map-get($zIndex, modals);
  pointer-events: all;
  display: flex;
  align-items: top;
}

.closeBtn {
  position: absolute !important;
  top: 24px;
  right: 26px;
}

:global {
  .modal-enter, .modal-appear {
    opacity: .01;
    transform: translate(-50%, -50%) scale(1.2);
  }

  .modal-enter.modal-enter-active, .modal-appear.modal-appear-active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: transform .45s cubic-bezier(.215, .61, .355, 1), opacity .45s cubic-bezier(.215, .61, .355, 1);
  }

  .modal-exit {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  .modal-exit.modal-exit-active {
    opacity: .01;
    transform: translate(-50%, -50%) scale(1.2);
    transition: transform .45s cubic-bezier(.215, .61, .355, 1), opacity .45s cubic-bezier(.215, .61, .355, 1);
  }

  .overlay-enter, .overlay-appear {
    opacity: .01;
  }

  .overlay-enter.overlay-enter-active, .overlay-appear.overlay-appear-active {
    opacity: 1;
    transition: opacity .45s cubic-bezier(.215, .61, .355, 1);
  }

  .overlay-exit {
    opacity: 1;
  }

  .overlay-exit.overlay-exit-active {
    opacity: .01;
    transition: opacity .45s cubic-bezier(.215, .61, .355, 1);
  }
}

// For desktop
@media only screen and (min-width: 600px) {
  .closeBtn {
    position: absolute !important;
    top: 26px;
    right: 26px;
  }
  .modal {
    border-radius: 12px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.container {
  padding: 0 8px 0 8px;
  margin: 0 4px 0 4px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  border: .5px solid transparent;
  transition: .2s;

  &:last-child {
    margin: 0 0 0 4px;
  }

  &:first-child {
    margin: 0 4px 0 -8px;
  }

  &.active {
    border-color: map-get($colors, gray600);
    background-color: map-get($colors, gray900);
  }

  &:hover, &.active:hover {
    background-color: map-get($colors, gray800);
  }

  &.isInactive {
    opacity: .2;
    pointer-events: none;
    cursor: auto;
  }
}

.filterType {
  white-space: nowrap;
  color: map-get($colors, gray400);
}

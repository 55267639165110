$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

// For mobile phones
.statContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stat {
  width: 100%;
  margin: 16px 0;
  padding: 16px;
  border: 1px solid map-get($colors, gray600);
  border-radius: 6px;
}

// For desktop
@media only screen and (min-width: 904px) {
  .statContainer {
    flex-direction: row;
  }

  .stat {
    margin: 0 16px 0 0;
    &:last-child {
      margin: 0;
    }
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.container {
  // border: .5px solid map-get($colors, gray600);
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: map-get($colors, gray800);
  }
}

.active {
  background: map-get($colors, gray900);
  &.dark {
    background: map-get($colors, gray800);
  }
}

.source {
  border: .5px solid transparent;
  margin-left: 10px;
  &:hover {
    border: .5px solid map-get($colors, gray600);
  }
  &.active {
    border: .5px solid map-get($colors, gray600);
  }
}

.label {
  font-size: 17px;
  color: map-get($colors, gray100);
}
.price {
  padding-left: 6px;
  display: inline;
}

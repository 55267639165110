$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';
@import './_FormIcon';

.children {
  display: table-cell;
  width: 100%;
  height: $iconLength; // Acts like a min-height
  vertical-align: middle;
  transition: .16s;
}

.text {
  padding-top: 1px; // Fudge factor to get text to line up with center of radio
  color: map-get($colors, gray100);
}

.icon.text {
  height: $iconLength !important;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

$foilDim: 62px;
$iconSize: 35px;

.container {
  cursor: pointer;
}

.previewContainer {
  position: relative;
  height: 272px;
  width: 100%;
  border-radius: 6px;
  background: map-get($colors, gray800);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.preview {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: 100%;
    width: 100%;
  }
}

/* stylelint-disable selector-class-pattern */
:global {
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.25s ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 0.25s ease-in;
  }
}
/* stylelint-enable */

.cardText {
  padding: 16px 8px 8px 8px;
  overflow: hidden; // don't let the product title run off the card ifithasalongwordinit
}

.cardTitle {
  min-height: 56px;
}

.dateText {
  min-height: 56px;
}

.hr {
  border-bottom: 1px solid map-get($colors, gray700);
  margin: 16px 0 16px;
}

.foilBadge {
  position: absolute;
  z-index: map-get($zIndex, foilBadgeGrid);
  top: 32px;
  right: 32px + $foilDim;
}

.metadata {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

// .overflowIcon {
//   position: absolute;
//   background-color: red;
// }

.badge {
  position: absolute;
  top: 0px;
  left: 8px;
}

.faceButton {
  fill: map-get($colors, gray300);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  height: 16px;
  width: 16px;
  &.active {
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.dropDown {
  position: absolute;
  top: 0%;
  right: 0%;
}

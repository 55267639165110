$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

$grid-gap: 20px;
$minRowHeight: 72px;

@function get-fallback-grid-item-width($columns) {
  @return calc((100% / #{$columns}) - (#{$grid-gap} * ((#{$columns} - 1) / #{$columns})));
}

.item {
  /* For alternative to CSS Grid. */
  height: $minRowHeight;
  width: get-fallback-grid-item-width(3);

  margin-right: $grid-gap;
  margin-bottom: $grid-gap;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

@supports (display: grid) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto; /* Let row height be set automatically by content. */
    grid-gap: $grid-gap;
  }

  .item {
    min-height: $minRowHeight;

    // override fallback
    height: initial;
    width: initial;
    margin: 0;
  }
}

.headerContainer {
  min-height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.titleRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}

.button {
  width: max-content;
}

/* For tablets: */
@media only screen and (min-width: 648px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
  .titleRow {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .title {
    margin-bottom: 0;
  }
}

/* For desktop: */
@media only screen and (min-width: 904px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

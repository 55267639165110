$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

//For mobile phones
// .container {
//   // padding: 40px 50px 50px 50px;
//   // width: 500px;
// }

.headerContainer {
  margin: 24px 0 16px 0;
}

.active {
  background: map-get($colors, gray900);
  &.dark {
    background: map-get($colors, gray800);
  }
}

.secondaryMessage {
  font-size: 16px;
  color: map-get($colors, gray400);
}

.collection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 8px 0 24px 0;
  width: 100%;
}

.selectWrapper {
  position: relative;
  margin-right: 8px;
  width: 100%;
  &:hover .dropdownArrow {
    fill: map-get($colors, gray100);
  }
}

.select {
  appearance: none;
  height: 40px;
  width: 100%;
  padding: 6px 16px 4px 8px;
  font-size: 16px;
  overflow-x: scroll;
  color: map-get($colors, gray100);
  background-color: map-get($colors, white);
  border: 1px solid map-get($colors, gray600);
  border-radius: 6px;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */

  & > option {
    text-transform: capitalize;
  }
}

.dropdownArrow {
  position: absolute;
  right: 12px;
  top: 13px;
  pointer-events: none;
  height: 14px;
  width: 14px;
  fill: map-get($colors, gray600);
}

.newCollectionContainer {
  display: flex;
  justify-content: space-between;
}

.newCollectionLinkWrapper {
  text-align: center;
}

// //For desktop
// @media only screen and (min-width: 600px) {

// }

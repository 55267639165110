$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

//For mobile phones
$sectionPadding: 16px;

.outerContainer {
  position: relative;
}

.innerContainer {
  font-size: 12px;
  font-family: $paraFamily;
  color: map-get($colors, gray100);
  transform: translateZ(0); // Fixes some jitter problems with fixed position and scrolling.
  width: 100%;

  &.fixed {
    top: 60px;
    right: auto;
    width: 350px;
  }
}

.section {
  padding: $sectionPadding;
  background: map-get($colors, white);
  border-radius: 4px;
  border-color: map-get($colors, gray600);
  border-width: 1px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  hr {
    margin-top: $sectionPadding;
    margin-right: -$sectionPadding;
    margin-bottom: $sectionPadding;
    margin-left: -$sectionPadding;
    border: none; // clear user-agent border settings
    border-bottom: 1px solid map-get($colors, gray600);
    opacity: .3;
  }
}

.header {
  display: flex;
  align-items: center;
}

.quantityDropdown {
  width: 70px;
  margin-right: 10px;
  flex-shrink: 0;
}

.quantityInput {
  height: 40px;
  width: 60px;
  margin: 0 10px 0 0;
  padding: 4px;
  font-weight: bold;
  text-align: right;
  color: map-get($colors, gray300);
  background-color: map-get($colors, gray900);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, .05);
}

.productDetail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.description {
  margin: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.detailsContainer {
  display: flex;
  align-items: center;
  padding-top: $sectionPadding;
}

.preview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.details {
  flex-grow: 1;
}

.selections {
  text-transform: capitalize; /* title-case the selections */
  padding-left: 2em;
  line-height: 18px;
  list-style: initial;
  & li {
    margin-bottom: 3px;
    color: map-get($colors, gray600); /* sets the list item color including bullet */
  }
}

.salesTaxInfo {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  cursor: pointer;
}

.submitButton {
  display: block; // eliminates some mystery after-margin from inline-block
  width: 100%;
  margin-top: $sectionPadding;
}

/* For tablets and Desktop: */
@media only screen and (min-width: 904px) {
  .innerContainer {
    &.fixed {
      position: fixed;
    }
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

// For mobile phones
.title {
  flex-grow: 1;
}

.contentContainer {
  justify-content: flex-start;
}

// .action {
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   color: map-get($colors, gray400);
//   fill: map-get($colors, gray400);
//   &:hover {
//     color: map-get($colors, blue400);
//     svg path {
//       stroke: map-get($colors, blue400);
//     }
//   }

//   &:not(:last-child) {
//     margin-right: 30px;
//   }

//   svg {
//     margin-right: 5px;
//     height: 20px;
//     width: 20px;
//   }
// }

.pageContent {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:after {
    content: none; // override Main's clearfix
  }
}

.form {
  width: 100%;
}

.submit {
  width: 100%;
}

/* For tablets and Desktop: */
@media only screen and (min-width: 904px) {
  .pageContent {
    flex-direction: row;
  }
  .submit {
    width: 350px;
    margin-left: 40px;
  }
  .form {
    width: 536px;
  }
}

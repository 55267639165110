$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.image {
  &:not(.loaded) {
    background: map-get($colors, gray800);
  }

  &.error {
    background: rgba(map-get($colors, red300), .1);
  }
}

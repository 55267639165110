$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';
//For Mobile
.main {
  padding-left: $mainPaddingMobile;
  padding-right: $mainPaddingMobile;
}

//For Desktop
@media only screen and (min-width: 648px) {
  .main {
    padding-left: $mainPadding;
    padding-right: $mainPadding;
    max-width: var(--vw-xxl);
    margin: 0 auto;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.background img {
  margin: 0 auto;
  text-align: center;
  width: $minWidth;
}

.container {
  background-color: map-get($colors, white);
  height: 300px;
  width: 800px;
  box-shadow: 0 2px 10px 0 rgba(map-get($colors, black), .2);
  margin: 200px 70px 0;
  position: absolute;
  top: 0;
}

.iconContainer {
  width: 220px;
  float: left;
  margin: 70px 57px 50px;
}

.warningContainer {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 57px;
}

.errorTitle {
  display: flex;
  margin: 67px 0 15px 0;
}

.errorMessage {
  display: flex;
  width: 406px;
}

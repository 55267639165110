$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.page {
  background-color: map-get($colors, white);
  min-height: 800px;
}

.header {
  box-shadow: none;
  margin-bottom: 0;
}

.disabled {
  pointer-events: none;
}

.uploadMetadata {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.galleryImages {
  height: 100%;
  position: relative;
}

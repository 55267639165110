// Fonts
$paraFamily: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$headFamily: '';

//--- Vendor overrides ---//
// WHCC overrides
@if $vendor == whcc {
  $headFamily: webfontregular + ', sans-serif';
}

// Fonts
$fonts: (
  paraFamily: $paraFamily,
  headFamily: $headFamily
);

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.layout {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: min-content;
  margin-top: 24px;
  border: .5px solid map-get($colors, gray600);
  border-radius: 6px;
  box-shadow: none;
  padding: 16px;
  transition: .2s;
  background: map-get($colors, white);
  &:hover {
    box-shadow: 0 0 0 6px rgba(map-get($colors, blue400), .2);
    cursor: pointer;

  }
}

.stripeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.description {
  width: 100%;
}

.image {
  height: 40px;
  width: auto;
  margin: 8px;
}

.optionsRow {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid map-get($colors, gray700);
}

.optionsRow > div {
  border: none;
  padding: 8px 4px;
  margin: 0;
}

.optionsRowIndent > div {
  padding-left: 30px;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .layout {
    display: flex;
    flex-direction: row;
  }

  .description {
    margin-right: 80px;
    width: 320px;
  }

  .button {
    width: 320px;
    margin-top: 0;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

$arrowSize: 85px;

//For mobile
.infoPanel {
  margin-top: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infoCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoCard {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 144px;
  margin: 32px;
}

.infoIcon {
  height: 56px;
  width: 56px;
  margin: 24px;
  stroke-width: 2px;
  stroke: map-get($colors, blue400);
}

.infoHeader {
  text-align: center;
}

.infoText {
  width: 184px;
  text-align: center;
}

.infoLink {
  display: block;
  color: map-get($colors, blue400);
}

// For desktop
@media only screen and (min-width: 768px) {
  .infoPanel {
    margin-top: 80px;
  }

  .infoCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .infoHeader {
    white-space: nowrap;
  }
}

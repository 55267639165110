$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

.productsWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.productCellWrapper {
  width: 100%;
}

.product {
  position: relative;
  width: calc(100% + 8px);
  margin: 0 0 0 -8px;
  height: 32px;
  background-size: cover;
  background-position: left bottom;
  border-radius: 6px;
  cursor: pointer;
}

.productLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 32px;
  width: 100%;
  padding: 0 8px 0 8px;
  border-radius: 6px;
  transition-duration: 0.1s;

  .label {
    color: map-get($colors, gray300);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
  }
}

.chevron {
  width: 12px;
  height: 12px;
  fill: map-get($colors, white);
  margin-right: 8px;
  transition: .1s;
  margin-left: auto;
}

.customBadge {
  padding: 1px 4px 2px 4px;
  margin-left: 8px;
  position: relative;
  color: map-get($colors , white);
  border-radius: 4px;
  background-color: map-get($colors , gray400);
}

/* For desktop: */
@media only screen and (min-width: 648px) {
  .productCellWrapper {
    :hover .productLabel {
      background-color: map-get($colors , gray800);
    }

    :hover .chevron {
      fill: map-get($colors, gray100);
      transform: translateX(12px);
    }

    :hover .label {
      color: map-get($colors, gray100);
    }
  }
}

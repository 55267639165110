$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.emptyText {
  margin-bottom: 20px;
  width: 52%;
}

.cardGrid {
  padding: 0;
}

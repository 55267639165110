$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.popOver {
  background-color: map-get($colors, white);
  border-radius: 6px;
  margin-top: 10px;
  overflow: visible;
  position: fixed;
  z-index: map-get($zIndex, popovers);
  pointer-events: all;
  box-shadow: 0 2px 10px 0 rgba(map-get($colors, black), .2);
  border: .5px solid map-get($colors, gray600);
  border-radius: 6px;
}

.content {
  padding: 10px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

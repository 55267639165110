$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

$white: map-get($colors, white);
$gray100: map-get($colors, gray100);
$gray600: map-get($colors, gray600);
$gray800: map-get($colors, gray800);

.item {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 5px;
  color: $gray100;
  background-color: $white;
}

.hover {
  border-top: 1px solid rgba($gray600, .3);
  border-bottom: 1px solid rgba($gray600, .3);
  background-color: $gray800;
}

.active {
  background-color: $gray800;
}

.text {
  color: inherit;
}

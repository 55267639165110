$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  min-height: 60px;
  width: 290px;
  border-top: 1px solid #D4D4D4;
}

.section > div:last-of-type {
  text-align: right;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  border: .5px solid map-get($colors, gray600);
  border-radius: 2px;
  padding: 4px 6px 4px 10px;
  margin: 5px 10px 5px 0;
}

.remove {
  $size: 15px;
  width: $size;
  height: $size;
  line-height: $size;
  font-size: 15px;
  text-align: center;
  color: map-get($colors, gray400);
  font-family: arial, sans-serif;
  cursor: pointer;
  margin-left: 10px;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.table table {
  width: 100%;
}

.table th {
  text-align: left;
}

.table td {
  padding: 8px 0;
  width: 60%;
}

.table td:nth-child(even) {
  text-align: right;
}

.table tbody tr:nth-child(1) {
  border-bottom: 1px solid map-get($colors, gray700);
}

.table tfoot {
  border-top: 1px solid map-get($colors, gray700);
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .table table {
    width: 320px;
  }
}

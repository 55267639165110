$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

$fontHeight: 36px;

.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid map-get($colors, gray700);
  // background-color: map-get($colors, gray800);
  height: 100%;
  width: 100%;
  transition: .2s;
  &:not(.isMaster) {
    cursor: pointer;
  }

  &:hover {
    background-color: map-get($colors, white);
    box-shadow: 0 0 0 6px map-get($colors, blue800);
  }
}

.title {
  display: flex;
  flex-wrap: nowrap;
}

.metaContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.badgeContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  align-self: flex-end;
  min-height: 20px;
}

.defaultBadge {
  margin: 0;
  border-radius: 4px;
  background-color: map-get($colors, gray100);
}

.lockIcon {
  height: 16px;
  width: 16px;

  margin-left: 8px;

  display: none;
  &.locked {
    display: block;
  }
}

.designCount {
  color: map-get($colors, gray400);
}

.icon {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}

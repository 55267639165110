$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 290px;
}

.name {
  padding: 20px;
  min-height: 60px;
}

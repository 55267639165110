$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.input {
  width: 100%;
}

.emailField {
  margin: 8px 0;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .input {
    width: 712px;
  }
}

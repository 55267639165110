$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

$disabled: map-get($colors, gray600);
$white: map-get($colors, white);

.lightbox {
  z-index: map-get($zIndex, lightbox);
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: all;
  user-select: none;
  background-color: rgba(map-get($colors, white), 1);
}

.header {
  margin-bottom: 0;
  border: none;
  max-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

.closeBtn {
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: map-get($colors, white);
  transition: 0.2s;
}

.closeSvg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke: map-get($colors, gray100);
  height: 24px;
  width: 24px;
  stroke-width: 1.5px;
  display: inline-block;
}

.closeBtn:hover {
  background-color: map-get($colors, blue800);
  &:hover .closeSvg {
    stroke: map-get($colors, blue300);
    height: 24px;
    width: 24px;
    stroke-width: 2px;
  }
}

.closeText {
  margin-left: 5px;
}

.headerButton {
  display: flex;
  flex-direction: row;
  padding: 9px 12px;
  margin: 0px 11px;
  top: 0px;
  left: 0px;
  cursor: pointer;
  &:hover .headerButtonText {
    text-decoration: underline;
  }
}

.iconContainer {
  height: 20px;
  width: 20px;
  fill: none;
  stroke: $white;
}

.headerButtonText {
  margin-left: 5px;
}

.pagerIcon {
  position: relative;
  stroke: map-get($colors, gray100);
  stroke-width: 1.5px;
  height: 24px;
  width: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.inactive {
    stroke: $disabled;
  }
}

.pagerButton {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: map-get($colors, gray800);
  transition: 0.2s;
}

.pagerButton:hover {
  background-color: map-get($colors, blue800);
  &.inactive {
    cursor: initial;
    background-color: map-get($colors, gray800);
  }
  .pagerIcon {
    stroke: map-get($colors, blue300);
    stroke-width: 2px;
    &.inactive {
      stroke: $disabled;
      stroke-width: 1px;
    }
  }
}

.pageText {
  width: 176px;
  text-align: center;
  padding-top: 10px;
  white-space: nowrap;
}

.pager {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: min-content;
  height: 40px;
  transition: 0.2s;
  z-index: 100;
}

.pagerContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 88px;
  // border-top: 1px solid map-get($colors, gray700);
}

.galleryItem {
  position: absolute;
  cursor: default;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.message {
  margin-top: 50px;
  width: 50%;
}

.cardGrid {
  padding-top: 10px;
}

.link {
  cursor: pointer;
  color: map-get($colors, blue500);
  padding: 4px;
  margin: 0 -4px;
  &:hover {
    color: map-get($colors, blue100);
  }
}

.button {
  display: block;
  margin: 50px auto 0;
  width: 150px;
}

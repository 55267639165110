$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.container {
  position: relative;
  // top: 0;
  // right: 0;
  z-index: map-get($zIndex, dropdown);
  height: 40px;
  // width: max-content;
  transition: max-height .5s;
  overflow: hidden;

  &.open {
    // width: 45%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 4px 1px rgba(map-get($colors, black), .2);
  }
}

.ellipsisContainer {
  padding: 8px;
  cursor: pointer;

  & > svg {
    height: 24px;
    width: 24px;
    font-size: 0;
    line-height: 0;
    fill: map-get($colors, gray400);
  }

  &:hover > svg {
    fill: rgba(map-get($colors, gray100), 1);
  }
}

.item {
  padding: 8px 16px;
  margin: 0; /* BECAUSE OF TEXT COMPONENT */
  width: 100%;
  white-space: nowrap;
  background-color: map-get($colors, white);
  color: map-get($colors, gray100);
  cursor: pointer;

  &.disabled {
    background-color: map-get($colors, gray400);
  }

  &:hover {
    background-color: map-get($colors, gray800);
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

$gray800: map-get($colors, gray800);

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  width: 100%;
}

.headerContainer {
  max-width: 200px;
  margin-bottom: 24px;
  text-align: center;
}

.iconContainer {
  height: 40px;
  width: 40px;
  margin: 0 auto 20px auto;
  box-shadow: 0 1px 2px 0 rgba(map-get($colors, black), .2);
  border-radius: 100%;
  fill: map-get($colors, blue500);
}

.orange300 {
  color: map-get($colors, white);
  background-color: map-get($colors, orange300);
  fill: map-get($colors, orange300);
}

.red300 {
  color: map-get($colors, white);
  background-color: map-get($colors, red300);
  fill: map-get($colors, red300);
}

.message {
  max-height: 100px;
  max-width: 200px;
  margin-bottom: 8px;
  text-align: center;
  overflow-y: auto;
  white-space: pre-wrap;

  &:after {
    position: absolute;
    left: 0;
    bottom: 100px;
    height: 20%;
    width: 100%;
    content: '';
    background: linear-gradient(to top, rgba($gray800, 1) 20%, rgba($gray800, 0) 80%);
    pointer-events: none; /* so the text is still selectable */
  }

  &.atBottom:after {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.actionButton {
  margin: 32px auto;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.addressContainer {
  margin-bottom: 20px;
}

.addressText {
  color: map-get($colors, gray300);
  font-weight: normal;
}

.state {
  margin: 0 3px;
}

.email {
  margin: 5px 0;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
// For Mobile
.selections {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

/* For desktop: */
@media only screen and (min-width: 900px) {
  .selections {
    width: 320px;
    margin-right: 80px;
    margin-top: 0;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.container {
  padding: 20px;
  background: map-get($colors, gray900);
  border-radius: 2px;
  border: .5px solid map-get($colors, gray600);
}

.stripe {
  background: map-get($colors, white);
}

.input {
  width: 50%;
  display: inline-block;
  &:last-of-type {
    padding-left: 20px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import './_FormIcon';
$labelMarginBottom: 15px;
$defaultIconLeftPadding: $iconLength + 9px;

.container.notActive, .container.notactive > * {
  cursor: pointer;
}

.hide {display: none !important;}

.disabled {
  opacity: .3 !important;
  pointer-events: none !important;
}

.container {
  width: 100%;
  display: block;
  padding-bottom: $labelMarginBottom;
  &:only-child {
    padding-bottom: 0;
  }
  &.notActive, &.noPad {
    padding-bottom: 0;
  }
}

.table {
  width: 100%;
  padding-left: $defaultIconLeftPadding;
  display: table;
  position: relative;
}

.thumb {
  padding-left: 0;
}

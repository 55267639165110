$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.inline {
  height: 100%;
  position: relative;
  display: inline-block;
}

.container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.arrow {
  position: absolute;
  &.up {
    border-bottom-color: currentColor !important;
    right: 0;
    bottom: 0;
  }
  &.right {
    border-right-color: currentColor !important;
    top: 0;
    right: 0;
  }
  &.left {
    border-left-color: currentColor !important;
    left: 0;
    bottom: 0;
  }
  &.down {
    top: 0;
    left: 0;
    border-top-color: currentColor !important;
  }
}

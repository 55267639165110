$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  // border: 1.5px dashed rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .05);
  transition-property: border-color;
  transition-duration: .2s;

  &:hover {
    border-color: rgba(0, 0, 0, .3);

    & .plusContainer {
      transform: translateY(-60%) translate(-50%, -50%);
    }
    & .plusLine {
      background-color: map-get($colors, gray400);
    }

    .text {
      opacity: 1;
      transform: translateY(10%);
    }
  }
}

.plusContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  transition: transform .2s;
}

.plusLine {
  position: absolute;
  left: 50%;
  width: 4px;
  height: 100%;
  border-radius: 4px;
  transform: translateX(-50%);
  background-color: map-get($colors, gray600);
  transition: background-color .2s;

  &:last-child {
    transform: translateX(-50%) rotate(90deg);
  }
}

.text {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  transform: translateY(-50%);
  transition-property: opacity, transform;
  transition-duration: .2s;
  color: map-get($colors, gray400);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

.titleContainer {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.cardGrid {
  // padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // gap: 32px; //not be supported in safari
  width: 100%;
}

.illustration {
  position: relative;
  width: 80%;
  max-width: 800px;
  padding: 40px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.arrow {
  display: none;
}

.empty {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.emptyText {
  text-align: center;
}

//For desktop
@media only screen and (min-width: 680px) {
  .arrow {
    position: absolute;
    display: initial;
    width: 25%;
    height: auto;
    stroke-width: 2px;
    top: 80%;
    left: -56px;
    transform: rotate(-20deg);
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

$bottomSectionMinHeight: 40px;

.container {
  hr {
    border: solid map-get($colors, gray700);
    border-width: 1px 0 0 0;
    margin-top: 12px;
  }
}

.containerWrapper {
  overflow-x: scroll;
  margin: 16px 0 0 -24px;
  padding: 0 24px;
  width: calc(100% + 48px);
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

.bottomSection {
  min-height: $bottomSectionMinHeight;
  display: flex;
  align-items: flex-start;
}

.tagsContainer {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;
}

.textReset {
  margin: 0;
}

.emptyMessage {
  flex-grow: 1;
}

.designCount,
.emptyMessage {
  flex-shrink: 0;
  height: $bottomSectionMinHeight;
  line-height: $bottomSectionMinHeight;
}

.colorDot {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.shapeIcon {
  margin-top: 0.5px; // Because the path end of drawing slightly top of off-center >:[

  path {
    stroke-width: 6%;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.container {
  flex-grow: 1;
}

.title {
  display: inline-block;
  vertical-align: middle;
  width: 296px !important;
  margin-bottom: 0;
}

.editable .title {
  margin: 0 8px 0 -12px;
}

.subtitleContainer {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.subtitle {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 12px 0 0;
  align-self: center;
  width: fit-content;
}

// For desktop
@media only screen and (min-width: 540px) {
  .title {
    width: 336px !important;
  }
}

@media only screen and (min-width: 648px) {
  .title {
    width: 464px !important;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: map-get($zIndex, stickyHeader); // keep this on top of everything
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

.titleCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #FFFFFF;
  border-bottom: 1px solid map-get($colors, gray700);
  margin-bottom: 16px;
  padding: 0 0 16px 0;
}

.titleSection {
  width: 50%;
}

.title {
  font-size: 20px;
}

.metaSection {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 20px;
  }

  :first-child {
    margin-left: 0;
  }
}

.designCount {
  font-size: 36px;
  font-weight: 500;
  font-family: map-get($fonts, paraFamily);
  color: map-get($colors, gray300);

  margin-left: 20px;
}

.icon {
  height: 40px;
  width: 40px;
}

.defaultBadge {
  position: relative;
  transition: opacity .25s;
}

.designManager {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.checkbox {
  vertical-align: middle;
}

.spinner {
  margin-top: 20px;
}

.cardGrid {
  position: relative;
}

.description {
  display: inline;
  vertical-align: middle;
  white-space: nowrap;

  margin-left: 8px;
}

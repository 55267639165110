$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.categoryTitle {
  height: 40px;
  width: 100%;
  margin: 16px 0;
  padding: 10px 5px 10px 10px;
  border-bottom: map-get($colors, gray700);
  position: relative;
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
}

.text {
  margin: 0;
  font-size: 18px;
  font-family: var(--font-family-display);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.group {
  cursor: pointer;
}

.img {
  width: 100%;
  padding: 0 16px;
}

.text {
  margin-bottom: 0;
}

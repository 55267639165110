$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import './styles/utils/utils.scss';

// For mobile phones
.shareLinkContainer {
  position: absolute;
  background-color: map-get($colors , white);
  border-top: 1px solid map-get($colors , gray700);
  width: 100%;
  padding: 24px 32px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: map-get($zIndex, modalFooter);
  border-radius: 0 0 12px 12px;
}

.copyButton {
  margin-left: 16px;
}

.copyInput {
  height: 40px;
  width: 100%;
  margin: 0;
}

.copyInput input {
  border: 1px solid map-get($colors , gray700) !important;
  // background-color: map-get($colors , gray800);
  padding: 0 0 0 8px;
  border-radius: 6px;
  color: map-get($colors , gray400);
}

// Button is disabled, but opacity should be 1
// TODO: This button is different enough - it should be a new component
.buttonActivated {
  opacity: 1 !important;
  padding: 7px;
}

.iconContainer {
  width: 16px;
  margin: auto;
}

.linkBtn {
  display: none;
}

// For desktops
@media only screen and (min-width: 600px) {
  .linkBtn {
    display: inline-flex;
  }
}

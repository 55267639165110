$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  background: map-get($colors, gray900);
  border-top: 1px solid map-get($colors, gray700);
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/utils';

.container {
  position: relative;
  height: 100%;
}

.containerProduct {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.product {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 0;
  object-fit: contain;
  vertical-align: bottom;
  cursor: pointer;
  &.lazy {
    /* Both hides the element and prevents the asset from being fetched. */
    display: none;
  }

  &:not(.loaded) {
    visibility: hidden;
  }
}

.photo {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
  vertical-align: bottom;
  cursor: pointer;
  &.lazy {
    /* Both hides the element and prevents the asset from being fetched. */
    display: none;
  }

  &:not(.loaded) {
    visibility: hidden;
  }
}

.errorIconContainer {
  width: 16px;
  height: 16px;
  stroke: map-get($colors, white);
  fill: map-get($colors, red300);

  &:hover ~ .arrow {
    opacity: 1;
  }

  &:hover ~ .errorOverlay {
    opacity: 1;
  }
}

.errorOverlay {
  position: absolute;
  z-index: 10; // keep this on top of everything
  width: 220px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: map-get($colors, gray600);
  top: 55%;
  right: 50%;
  background: map-get($colors, white);
  opacity: 0.01;
  transition: 0.2s;

  &::after {
    $size: 11px;
    width: $size;
    height: $size;
    background-color: map-get($colors, white);

    content: '';
    position: absolute;
    top: -6px;
    left: calc(50% - #{$size} / 2);
    transform: rotate(45deg);
    border-left: 1px solid map-get($colors, gray600);
    border-top: 1px solid map-get($colors, gray600);
  }
}

.errorText {
  text-align: center;
  padding: 10px 10px;
  overflow: hidden;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
.total {
  margin: 0;
}

.unit {
  margin-right: 4px;
}

.pricing {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

.badgeContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 0px;
  transition: opacity .2s;
  &:hover > .errorListOverlay {
    opacity: 1;
  }
  &.hide {
    display: none;
  }
}

.errorsIconContainer {
  width: 16px;
  height: 16px;
  stroke: map-get($colors, white);
  fill: map-get($colors, red300);
  margin-right: 4px;
}

.checkIconContainer {
  width: 16px;
  height: 16px;
  stroke: map-get($colors, white);
  fill: map-get($colors, green300);
  margin-right: 4px;
}

.completeText {
  margin: auto;
  color: map-get($colors, green300);
  white-space: nowrap;
}

.errorsText {
  margin: auto;
  color: map-get($colors, red300);
}

.errorListContainer {
  display: none;
}

.errorListOverlay {
  position: absolute;
  top: 125%;
  margin: auto;
  right: -100%;
  z-index: 10;  // keep this on top of everything
  width: 220px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: map-get($colors, gray600);
  background: map-get($colors, white);
  opacity: .01;
  transition: .2s;
  box-shadow: 0 2px 10px rgba(map-get($colors, black), .2);
  &::after {
    $size: 11px;
    width: $size;
    height: $size;
    background-color: map-get($colors, white);

    content: '';
    position: absolute;
    top: -6px;
    left: calc(50% - #{$size} / 2);
    transform: rotate(45deg);
    border-left: 1px solid map-get($colors, gray600);
    border-top: 1px solid map-get($colors, gray600);
  }
}

.errorListText {
  text-align: center;
  padding: 10px 10px;
}

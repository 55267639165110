$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import 'styles/utils/colors';

$gutter: 24px;
$columnWidthSidebar: 224px;
$rowHeightHeader: 88px;
$rowHeightFooter: 88px;
$columnWidthPreview: calc(
  100% - 3 * #{$gutter} - #{$columnWidthSidebar}
);
$rowHeightPreview: calc(
  100% - #{$rowHeightHeader} - #{$rowHeightFooter} - 2 * #{$gutter}
);
$rowHeightPreviewNoFooter: calc(
  100% - #{$rowHeightHeader} - 2 * #{$gutter}
);

// For Mobile Phones
.container {
  // width: calc(100vw - #{$gutter} * 2;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: map-get($colors, white);
  border-radius: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */

  &.noSidebar,
  &.noSidebarOrNav {
    .preview {
      padding: 0 24px 0 24px;

      /* For alternative to CSS Grid. */
      width: 100%;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 24px 32px;
  border-bottom: 1px solid map-get($colors, gray700);
  border-radius: 0;
  background-color: white;

  /* For alternative to CSS Grid. */
  width: 100%;
  // height: 56px;
  margin-bottom: $gutter;
}

.preview {
  // padding: 0 60px;

  /* For alternative to CSS Grid. */
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  // margin-bottom: $gutter;
}

.sidebar {
  position: relative;
  padding: 0 24px 24px 24px;

  /* For alternative to CSS Grid. */
  display: inline-block;
  vertical-align: top;
  height: calc(#{$rowHeightPreview} + #{$gutter});
}

.footer {
  grid-area: footer;

  /* For alternative to CSS Grid. */
  width: 100%;
  height: $rowHeightFooter;
  // margin-top: $gutter;
}

@supports (display: grid) {
  .container {
    display: grid;
    grid-template-columns: $gutter auto $gutter;
    grid-template-rows: $rowHeightHeader $gutter 40vh $gutter $rowHeightFooter $gutter auto $gutter;
    /* stylelint-disable declaration-colon-space-after */
    grid-template-areas:
      'header  header  header'
      '.     .     .'
      '.    preview    .'
      '.     .     .'
      'footer  footer  footer'
      '.      .       .'
      'sidebar  sidebar  sidebar'
      '.      .       .'
    ;

    &.noNav {
      grid-template-columns: $gutter auto $gutter;
      grid-template-rows: $rowHeightHeader $gutter 40vh $gutter auto $gutter;
      grid-template-areas:
        'header  header  header'
        '.     .     .'
        '.    preview    .'
        '.     .     .'
        'sidebar  sidebar  sidebar'
        '.      .       .'
        ;
    }

    &.noSidebar {
      grid-template-columns: auto;
      grid-template-rows: $rowHeightHeader $gutter auto $gutter $rowHeightFooter;
      grid-template-areas:
        'header '
        '.      '
        'preview'
        '.      '
        'footer ';

      .preview {
        width: initial;
      }
    }

    &.noSidebarOrNav {
      grid-template-columns: auto;
      grid-template-rows: $rowHeightHeader $gutter auto $gutter;
      grid-template-areas:
        'header'
        '.     '
        'preview'
        '.     ';
    }
    /* stylelint-enable */
  }

  .header {
    grid-area: header;

    /* Reset values used for alternative solution. */
    width: initial;
    height: initial;
    margin-bottom: initial;
  }

  .preview {
    grid-area: preview;

    /* Reset values used for alternative solution. */
    display: initial;
    vertical-align: initial;
    width: initial;
    height: initial;
    margin-right: initial;
  }

  .sidebar {
    grid-area: sidebar;

    /* Reset values used for alternative solution. */
    display: initial;
    vertical-align: initial;
    width: initial;
    height: initial;
  }

  .footer {
    grid-area: footer;

    /* Reset values used for alternative solution. */
    width: initial;
    height: initial;
    margin-top: initial;
  }
}

// For desktop
@media only screen and (min-width: 600px) {
  .container {
    overflow-y: none;
  }

  .sidebar {
    // padding: 0 24px 0 0;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* Firefox 64 */
    position: relative;
    padding: 0;

    /* For alternative to CSS Grid. */
    display: inline-block;
    vertical-align: top;
    width: $columnWidthSidebar;
    height: auto;
  }
  @supports (display: grid) {
    .container {
      display: grid;
      grid-template-columns: $gutter $columnWidthPreview $gutter $columnWidthSidebar $gutter;
      grid-template-rows: $rowHeightHeader $gutter $rowHeightPreview $gutter $rowHeightFooter;
      /* stylelint-disable declaration-colon-space-after */
      grid-template-areas:
        'header  header  header  header  header'
        '.     .       .      .      .'
        '.     preview .      sidebar      .'
        '.     .       .      sidebar      .'
        'footer  footer  footer footer footer'
        ;

      &.noNav {
        grid-template-columns: $gutter $columnWidthPreview $gutter $columnWidthSidebar $gutter;
        grid-template-rows: $rowHeightHeader $gutter $rowHeightPreviewNoFooter $gutter;
        grid-template-areas:
          'header  header  header  header  header'
          '.     .       .      .      .'
          '.     preview .      sidebar      .'
          '.     .       .      sidebar      .'
          ;
      }
      &.noSidebar {
        grid-template-columns: auto;
        grid-template-rows: $rowHeightHeader $gutter calc(
          100% - 2 * #{$gutter} - #{$rowHeightHeader} - #{$rowHeightFooter}) $gutter $rowHeightFooter;
        grid-template-areas:
          'header '
          '.      '
          'preview'
          '.      '
          'footer '
        ;
      }
    }
  }
}

$zIndex: (
  hidden: -1,
  popoverArrow: -1,
  landingOverlay: 1,
  landingElems: 2,
  photoGrid: 100,
  foilBadgeGrid: 200,
  cardHover: 250,
  stickyHeader: 275,
  dropdown: 500,
  lightbox: 1000,
  overlay: 1100,
  popovers: 1200,
  modals: 1300,
  modalFooter: 1310,
  foilBadgeModal: 1400,
  header: 1400
);

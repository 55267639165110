$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

$swatchSize: 40px;
$swatchSpacing: 10px;

.container {
  display: flex;
  flex-wrap: wrap;
  width: 260px;
  padding: $swatchSpacing;
}

.option {
  position: relative;
}

/* Customize the label (the swatch) */
.swatch {
  height: $swatchSize;
  width: $swatchSize;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, .1);
  margin: $swatchSpacing;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  transition: .2s transform;
  &:hover {
    transform: scale(1.2);
  }
}

/* Hide the browser's default checkbox */
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Position the check */
.check {
  position: absolute;
  width: $swatchSize;
  height: $swatchSize;
  top: $swatchSize - $swatchSpacing;
  left: $swatchSize - $swatchSpacing;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.header {
  margin-bottom: 8px;
  position: sticky;
  position: -webkit-sticky; //safari
  top: 0;
  z-index: map-get($zIndex, stickyHeader); // keep this on top of everything
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */

  /* Special bottom padding to accommodate DesignFiltersSection :( */
  padding-bottom: 9px;

  & .title {
    /* Needs to be in here for specificity. */
    width: 80vw !important;
  }
  & .ornament {
    /* Needs to be in here for specificity. */
    width: 240px;
  }
}

.designFilterSection {
  align-self: stretch;
}

.mainContent {
  margin-top: 10px;
  max-width: 1528px;
  margin: auto;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.subtitle {
  margin-left: 0;
}

@import '../../../styles/utils/utils';
@import '../text/Text.scss';

$inputDefaultHeight: 40px;
$inputPadding: $inputDefaultHeight / 4;

.default {
  border: 1px solid map-get($colors, gray600);
  border-radius: 6px;
  height: $inputDefaultHeight;
  width: 100%;
  padding: $inputPadding 0 $inputPadding $inputPadding;

  color: map-get($colors, gray100);
  font-family: var(--font-family-display);
  font-weight: 500;
  font-size: 17px; //h3
  line-height: normal;

  &:invalid {
    border-color: map-get($colors, red300);
    background-color: rgba(map-get($colors, red300), 0.1);
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.badge {
  padding: 4px 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  // height: 16px;
  border-radius: 4px;
  display: inline-block;
}

//badge defaults
.default {
  color: map-get($colors, transparent);
  background-color: map-get($colors, transparent);
}

//badge types
.blue {
  color: map-get($colors, white);
  background-color: map-get($colors, blue500);
}

.orange {
  color: map-get($colors, white);
  background-color: map-get($colors, orange300);
}

.green {
  color: map-get($colors, white);
  background-color: map-get($colors, green300);
}

.gray {
  color: map-get($colors, white);
  background-color: map-get($colors, gray600);
}

.purple {
  color: map-get($colors, white);
  background-color: #A64373;
}

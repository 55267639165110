$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

/* For mobile phones: */
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 32px;
  padding: 24px;
  border-bottom: 1px solid map-get($colors, gray700);
  max-width: var(--vw-xxl);
  margin: 0 auto 32px auto;
}

.modal {
  padding: 26px 0 24px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 24px;
  border-bottom: 1px solid map-get($colors, gray700);
  overflow-x: scroll;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > .titleHeadingWrapper {
    flex-grow: 1;
  }
}

.sectionModal {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // margin-top: 16px;
  width: 100%;

  & > .titleHeadingWrapper {
    flex-grow: 1;
    width: 100%;
  }
}

.buttonClusterWrapper {
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
  padding: 24px 0 0 0;
  margin: 0 -24px 0 -24px;
}

.buttonCluster {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  width: min-content;
  padding: 0 24px 0 24px;
  *:not(:first-child) {
    margin-right: 16px;
    margin-left: 0;
  }
}

.titleHeadingWrapper {
  display: inline-block;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.modalTitle {
  font-size: 22px; //h2
  width: calc(100% - 48px) !important;
}

.subtitle {
  display: inline;
  white-space: nowrap;
  margin: 7px auto 0 8px;
}

.upLinkContainer {
  color: map-get($colors, gray100);
  margin-right: 16px;

  &:hover {
    color: map-get($colors, blue500);

    & .label {
      color: map-get($colors, blue500);
    }
  }
}

.chevron {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke: map-get($colors, gray100);
  height: 24px;
  width: 24px;
  stroke-width: 1.5px;
  transition: 0.2s;
}

.backButton {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: map-get($colors, white);
  transition: 0.2s;
  &:hover {
    background-color: map-get($colors, blue800);

    .chevron {
      stroke: map-get($colors, blue300);
      stroke-width: 2px;
    }
  }
}

.upLink {
  vertical-align: middle;

  & > .label {
    display: inline;
    -webkit-text-fill-color: map-get($colors, gray100); // override safari disabled input text color
    opacity: 1;
    font-size: 18px;
    font-weight: 500;
    transition-duration: 0.2s;
  }
}

/* For desktop: */
@media only screen and (min-width: 648px) {
  .container {
    padding: 1.5rem var(--hor-space) 1.5rem var(--hor-space); //sets the left and right padding to be larger on desktop
  }
}

@media only screen and (min-width: 904px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    margin-bottom: 32px;
    border-bottom: 1px solid map-get($colors, gray700);
  }
  .section {
    flex-direction: row;
  }
  .buttonClusterWrapper {
    overflow-x: inherit;
    padding: 0;
    margin: 0;
    align-self: center;
  }
  .buttonCluster {
    margin-top: 0;
    flex-direction: row;
    padding: 0;
    *:not(:first-child) {
      margin-right: 0;
      margin-left: 16px;
    }
  }
  .upLinkContainer {
    margin-right: 32px;
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';

.mainContainer {
  margin: 20px 0;
}

.span {
  position: relative;
  float: left;
}

.row {
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

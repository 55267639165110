$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../styles/utils/utils';
@import '../styles/utils/zIndex';

/* For mobile phones: */
.page {
  background-color: map-get($colors, white);
}

.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: map-get($zIndex, stickyHeader); // keep this on top of everything
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 */
}

.header {
  box-shadow: none;
  margin-bottom: 0;
}

.uploadMetadata {
  display: flex;
  align-items: center;
  margin-top: 8px;
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}

.savedProductsContainer {
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column-reverse;
  // gap: 32px; //not supported by safari
  background-color: map-get($colors, white);
  padding-top: 32px;
  &.unbranded {
    background-color: map-get($colors, white);
  }
}

.newProductBtn {
  display: inline-flex;
  align-content: center;
  margin-right: 16px;
}

.mobileGalleryBtn {
  display: inline-flex;
  align-content: center;
}

.headerDescription {
  white-space: nowrap;
}

.cardContentContainer {
  display: none;
}

.thumbnail {
  margin: 0;
  vertical-align: top;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.galleryBtn {
  width: auto;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .8;
}

.iPadLink {
  display: none;
}

/* For tablets and Desktop: */
@media only screen and (min-width: 648px) {
  .contentContainer {
    flex-direction: row;
  }
  .newProductContainer {
    width: 320px;
    margin: 0 56px 0 0;
  }
  .newProductBtn {
    display: none;
  }
  .mobileGalleryBtn {
    display: none;
  }
  .headerDescription {
    white-space: nowrap;
  }
  .cardContentContainer {
    position: relative;
    height: 120px;
    width: 100%;
    overflow: hidden;
    border-radius: 6px;
    margin-bottom: 40px;
    display: inline-block;
    background-color: map-get($colors, gray800);
    transition: box-shadow .2s;
    :hover {
      opacity: 1;
    }
    &:hover {
      box-shadow: 0 0 0 6px map-get($colors, blue800);
    }
  }

  .iPadLink {
    display: block;
    position: relative;
    padding: 0 0 24px 0;
    margin: 40px 0 0 0;
    width: 100%;
    line-height: 1.4;
    text-align: left;
    color: map-get($colors, gray100);
    background-color: map-get($colors, gray800);
    box-shadow: 0 0 0 12px map-get($colors, gray800);
    border-radius: 1px;
  }

  .learnMore {
    position: absolute;
    font-weight: bold;
    margin-top: 8px;
    color: map-get($colors, gray100);
    &:hover {
      color: map-get($colors, blue400);
    }
  }
}

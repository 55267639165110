$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

.drop { // shared drop styles
  left: 0;
  top: 100%;
  width: auto;
  height: 40px;
  position: relative;
  .dropDown {
    background-color: map-get($colors, white);
    border: 1px solid map-get($colors, gray600);
    overflow: auto;
    position: absolute;
    z-index: map-get($zIndex, dropdown);
    width: 100%;
  }
}

.default { // specific type
  .dropDown {
    border-top-width: 0;
  }
}

.arrow { // specific type
  padding-top: 10px;
  .dropDown {
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(map-get($colors, black), .2);
  }
}

$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../../styles/utils/utils';

.dropZone {
  color: map-get($colors, blue500);
  min-width: $minWidth;
  height: 100%;
  width: calc(100% - 14px);
  padding: 5px;
  position: absolute;
  margin: 0 auto;
  left: 5px;
  right: 5px;
  z-index: 0;
  visibility: hidden;
  overflow: hidden;
  &.active {
    border: 2px dashed map-get($colors, blue500);
    background-color: rgba(map-get($colors, blue500), .1);
    z-index: map-get($zIndex, photoGrid);
    visibility: visible;
  }
}

.dropZoneText {
  color: map-get($colors, blue500);
}

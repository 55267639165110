$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../styles/utils/utils';

.container {
  padding: 20px 30px;
  user-select: none;
}

.option {
  display: flex;
  align-items: center;
  height: 25px;
  cursor: pointer;
}

.input {
  cursor: pointer;
}

.label {
  margin: 0px 5px;
  color: map-get($colors, black);
}

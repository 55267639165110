$vendor: whcc;$cdnAssetsUrl: "https://cdn.dragdrop.design";$baseUrl: "https://studio.whcc.com";
@import '../../../styles/utils/utils';

$IconWidth: 20px;
.statusIcon {
  background-color: map-get($colors, default);
  border-radius: 100%;
  height: $IconWidth;
  width: $IconWidth;
  z-index: 10000;
}
